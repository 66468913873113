<template>
  <div class="w-100">
    <!--begin::Content header-->
    <div class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">

    </div>
    <!--end::Content header-->

    <!--begin::Signin-->
    <div class="row">
      <div class="col-12 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 col-xxl-4 offset-xxl-4 login-form login-signin">
        <!-- begin::Loading -->
        <template v-if="isLoading">
          <div class="text-center mb-10 mb-lg-20">
            <h3 class="font-size-h1">Invite</h3>
            <p class="text-muted font-weight-semi-bold">
              Please wait...
            </p>
          </div>
        </template>
        <!-- end::Loading -->

        <!-- begin::Register -->
        <template v-if="register">
          <div class="text-center mb-10 mb-lg-20">
            <h3 class="font-size-h1">Create your account</h3>
            <p class="text-muted font-weight-semi-bold">
              Enter your details below
            </p>
          </div>

          <!-- begin::Error alert -->
          <template v-if="isErrorActive">
            <error-translation-alert
              class="mt-n10 mb-10"
              ref="errorTranslationAlert"
              :error="activeError"
            />
          </template>
          <!-- end::Error alert -->

          <!--begin::Form-->
          <form class="form" @submit.stop.prevent="onSubmit">
            <div class="form-group">
              <input
                ref="email"
                type="email"
                placeholder="Email address *"
                :class="['form-control form-control-solid h-auto py-5 px-6', { 'is-invalid': $v.form.email.$error }]"
                v-model="$v.form.email.$model"
              />
            </div>

            <!-- begin::Password -->
            <div class="form-group">
              <input
                ref="password"
                type="password"
                placeholder="Password *"
                :class="['form-control form-control-solid h-auto py-5 px-6', { 'is-invalid': $v.form.password.$error }]"
                v-model="$v.form.password.$model"
              />
            </div>

            <div class="form-group">
              <input
                type="password"
                placeholder="Confirm password *"
                :class="['form-control form-control-solid h-auto py-5 px-6', { 'is-invalid': $v.form.password_confirm.$error }]"
                v-model="$v.form.password_confirm.$model"
              />
            </div>
            <!-- end::Password -->

            <div class="separator separator-solid mt-n3 mb-5" />

            <!-- begin::Personal information -->
            <div class="form-group">
              <input
                type="text"
                placeholder="First name"
                :class="['form-control form-control-solid h-auto py-5 px-6', { 'is-invalid': $v.form.firstname.$error }]"
                v-model.trim="$v.form.firstname.$model"
              />
            </div>

            <div class="form-group">
              <input
                type="text"
                placeholder="Last name"
                :class="['form-control form-control-solid h-auto py-5 px-6', { 'is-invalid': $v.form.lastname.$error }]"
                v-model.trim="$v.form.lastname.$model"
              />
            </div>
            <!-- end::Personal information -->

            <div class="separator separator-solid mt-n3 mb-5" />

            <!-- begin::Terms & Conditions -->
            <div class="form-group">
              <label class="checkbox mb-0">
                <input
                  type="checkbox"
                  v-model="$v.form.terms_and_conditions.$model"
                  class=""
                >
                <span :class="[{'border-danger': $v.form.terms_and_conditions.$error}]" />
                <div class="text-center w-100">
                  I agree to the <a href="#">terms and conditions</a>.
                </div>
              </label>
            </div>
            <!-- end::Terms & Conditions -->

            <!--begin::Action-->
            <div class="form-group d-flex flex-wrap justify-content-between align-items-center">
              <router-link :to="{ path: '/login', query: { email: (form.email || undefined), invite: (token || undefined) }}" class="text-dark-60 text-hover-primary my-3 mr-2">
                Already have an account?
              </router-link>
              <button ref="submitButton" class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3">
                Submit
              </button>
            </div>
            <!--end::Action-->
          </form>
          <!--end::Form-->
        </template>
        <!-- end::Register -->
      </div>
      <!--end::Signin-->
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, email, sameAs } from 'vuelidate/lib/validators';
import { validations, errorComponentMixin } from '@vedicium/core-vue';
import errorTranslationAlert from '@/components/errors/translation.alert.vue';

import { CompanyInviteClass as CompanyInvite, CompanyClass as Company } from '@vedicium/core-vue/build/modules/company';

export default {
  mixins: [validationMixin, errorComponentMixin],
  components: {
    errorTranslationAlert,
  },
  data () {
    return {
      isLoading: true,
      register: false,

      token: null,
      form: {
        email: null,

        password: null,
        password_confirm: null,

        firstname: null,
        lastname: null,
        terms_and_conditions: false,
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },

      password: {
        required,
      },
      password_confirm: {
        required,
        sameAsPassword: sameAs('password'),
      },

      firstname: {},
      lastname: {},
      terms_and_conditions: {
        required,
        sameAs (value) {
          return value === true;
        },
      },
    },
  },
  async mounted () {
    this.$set(this.$parent, 'isMounted', false);
    this.$set(this, 'token', this.$route.query.token);

    // On authenticated
    if (this.$auth.authenticated === true) {
      // Redirect to dashboard with invite
      this.$router.push({
        path: '/',
        query: {
          invite: this.token,
        },
      });
      return;
    }

    const token = await CompanyInvite.verify(this.token, { skipAuth: true });
    if (token.resource_exists === true) {
      this.$router.push({
        path: '/login',
        query: {
          email: (this.$route.query.email || null),
          redirect_uri: `/dashboard?invite=${this.token}`,
        },
      });
      return;
    }

    this.$set(this, 'isLoading', false);

    this.$set(this.form, 'email', (this.$route.query.email || null));

    this.$set(this, 'register', true);
  },

  methods: {
    async validate () {
      const validationArray = [this.$v];

      // Run the validations
      await validations.validateArray(validationArray);
    },
    async onSubmit () {
      this.$errors.clear({ component: this });

      // Set spinner to submit button
      this.$refs.submitButton.classList.add('spinner', 'spinner-light', 'spinner-right');

      let response = null;
      try {
        await this.validate();

        response = await this.$ws.post(`${Company.uri}/invite/${this.token}/register`, {
          body: {
            email: this.form.email,
            password: this.form.password,
            firstname: this.form.firstname,
            lastname: this.form.lastname,
          },
        });

        // When authentication is given, try this
        if (response.accesstoken && response.refreshtoken) {
          this.$auth.saveCredentials({
            resource: response.resource,
            accesstoken: response.accesstoken,
            refreshtoken: response.refreshtoken,
            expiry: response.expiry,
          });

          await this.$auth.checkAuthenticated();

          this.$router.push({
            path: '/dashboard',
            query: {
              invite: this.token,
            },
          });
          return;
        }
      } catch (e) {
        console.error(e);
        this.$errors.handle(e, { component: this, ui_element: false });
        return;
      } finally {
        this.$refs.submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right');
      }

      // By default, go to login page
      this.$router.push({
        path: '/login',
        query: {
          email: this.form.email,
          redirect_uri: `/dashboard?invite=${this.token}`,
        },
      });
    },
  },
};
</script>
